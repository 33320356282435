*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans";
}
:where(ul, a, a:active, a:visited){
  list-style-type: none;
  color: black;
  text-decoration: none;
}
:where(button){
  cursor: pointer;
}
:where(li){
  cursor: pointer;
}
.container{
  margin: 0 2rem;
}
/* MOBILE NAV */
.mobile-icon {
  font-size: 1.5rem;
  display: none;
}
.mobile-nav-con{
  background-color: white;
  width: 100%;
  padding: 1rem;
}
.mobile-nav-con >ul li{
  padding: 0.3rem 0;
}

.mobile-nav-con >button{
  background-color: red;
  color: white;
  padding: 0.6rem 0;
  width: 9rem;
  border: none;
  border-radius: 5px;
  margin-top: 1rem;
}
/* NAVIGATION */
nav{
  box-shadow: 20px 0 40px rgb(207, 204, 204);

}
.nav-con{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.nav-con >ul{
  display: flex;
  align-items: center;
}
.nav-con >ul li{
  padding-left: 1rem;
  transition: 0.5s;
}
.nav-con >ul li:hover, a:hover{
  color: #695BA7;
  transition: 0.5s;
}
.nav-con >button{
  background-color: red;
  color: white;
  padding: 0.6rem 0;
  width: 9rem;
  border: none;
  border-radius: 5px;
}
.logo-con > img{
  width: 4rem;
}


/* HERO SECTION */
.hero-text-con{
  display: grid;
  place-items: center;
  text-align: center;
  margin-top: 5rem;
}
.hero-text-con >h1{
  font-size: 3rem;
  margin-bottom: 0.5rem;
}
.hero-btn-con{
  margin-top: 1rem;
}
.hero-btn-con >button{
  background-color: red;
  color: white;
  padding: 0.6rem 0;
  width: 9rem;
  border: none;
  border-radius: 5px;
}
.hero-btn-con >button a{
  color: white;
}
.hero-img-con{
  display: grid;
  place-items: center;
}
.hero-img-con >img{
  width: 20rem;
}
/* SLIDER SECTION  has it`s own CSS file now, the file name is SliderSectionStyles.css. It is inside the Styles folder*/

/* ABOUT / PARTNERING SECTION */
.about-text-con{
  display: grid;
  place-items: center;
  margin-top: 6rem;
  
  
}
.about-text-con >p{
  text-align: center;
  line-height: 1.8rem;
  font-size: 1.4rem;
}
.about-btn-con{
  margin-top: 1rem;
}
.about-btn-con >button{
  background-color: red;
  width: 8rem;
  padding: 0.5rem 0;
  color: white;
  border: none;
  border-radius: 10px;
}

/* when */
.when-section{
  margin-top: 6rem;
  background-color: #695BA7;
  padding: 1.5rem;
}
.when-background-con{
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),   url("https://images.pexels.com/photos/3153207/pexels-photo-3153207.jpeg?auto=compress&cs=tinysrgb&w=600");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  width: 40rem;
  height: 20rem;
  margin: 0 auto;
  border-radius: 5px;
}
.when-background-con >h2{
  color: white;
  font-size: 2.5rem;
  text-align: center;
  padding-top: 1rem;
}
/* FEATURES*/
.feature-section{
  margin-top: 6rem;
}
.feature-section > h2{
  text-align: center;
  margin-bottom: 1rem;
}
.feature-section >p{
  text-align: center;
  margin-bottom: 2rem;
}
  

.feature-grid-con{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 0.5rem;
  place-items: center;
  grid-auto-flow: dense; 
}
.feature-grid-con:last-child{
  justify-self: center;
  align-self: center;
 
}
.grid-elements{
  width: 45rem;
  height: 20rem;
  background-color: #695BA7;
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 3rem;
  border-radius: 10px;
  color: white;
}
.grid-element-text{
  text-align: center;
  padding-top: 7rem;
  padding: 7rem  1rem 1rem 1rem;
}

.background-element-one, .background-element-two,.background-element-three, .background-element-four, .background-element-five, .background-element-six,.background-element-seven{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0  10px  10px;
}
.background-element-one{
  background-image: url("./Component/Images/techies.jpg");
 
}

.background-element-two{
  background-image: url("./Component/Images/cultural.jpg");
  
}
.background-element-three{
  background-image: url("./Component/Images/innovation.jpg");
}
.background-element-four{
  background-image: url("./Component/Images/networking.jpg");
}
.background-element-five{
  background-image: url("./Component/Images/idea.jpg");
}
.background-element-six{
  background-image: url("./Component/Images/digital-skills.jpg");
}
.background-element-seven{
  background-image: url("./Component/Images/africans\ learning\ tech.jpg");
  
}
 .center{
   grid-column: 1 / 3;
}

/* FOOTER */
footer{
  padding: 1rem;
  margin-top: 4rem;
}
footer > h2{
  text-align: center;
}
.footer-logos-con{
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.footer-logos-con img{
  width: 7rem;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: color-burn;
}
.footer-con{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}
.safari-business > h4, .career >h4, .terms >h4{
  margin-bottom: 1rem;
}
footer > hr{
  margin-top: 1rem;
}
.last-footer-con{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.last-footer-con > ul{
  display: flex;
  align-items: center;
}
.last-footer-con > ul li{
  padding-left: 0.5rem;
  cursor: pointer;
}
.footer-last-logo > img{
  width: 5rem;
}
@media (max-width:1309px) {
  .grid-elements{
    width: 38rem;
  }
  .feature-grid-con{
   gap: 1rem;
  }
}
@media (max-width:1277px) {
  .grid-elements{
    width: 35rem;
  }
}
@media (max-width:1175px) {
  .container{
    margin: 0 1rem;
  }
  .grid-elements{
    width: 33rem;
  }
  .feature-section{
    margin-top: 6rem;
  }
}
@media (max-width:1099px) {
  .grid-elements{
    width: 30rem;
  }
  .grid-element-text{
    
    padding-top: 3rem;
    
  }
  .feature-grid-con{
    gap: 0.5rem;
   }
}
@media (max-width:1012px) {
  .grid-elements{
    width: 25rem;
  }
  
}
@media (max-width:827px) {
  .grid-elements{
    width: 23rem;
  }
  .grid-element-text{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (max-width:771px) {
  .nav-con > ul, .nav-con >button{
    display: none;
  }
  .mobile-icon{
    display: block;
  }
  .feature-grid-con{
    grid-template-columns: 1fr;
   
  
  }
  .grid-elements{
    width: 40rem;
  }
   .center{
   grid-column: auto;
}
}
@media (max-width:675px) {
  .grid-elements{
    width: 35rem;
  }
  .when-background-con{
    width: 35rem;
    
  }
  .footer-con{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
  }
}
@media (max-width:597px) {
  .when-background-con{
    width: 30rem;
    
  }
  .grid-elements{
    width: 30rem;
  }
}
@media (max-width:557px) {
  .footer-logos-con img{
    width: 5rem;
    
  }
}
@media (max-width:511px) {
  .when-background-con{
    width: 27rem;
    
  }
  .grid-elements{
    width: 27rem;
  }
  .when-background-con >h2{
    color: white;
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
  }
  .break-tag{
    display: none;
  }
}
@media (max-width:463px) {
  .when-background-con{
    width: 23rem;
    
  }
  .grid-elements{
    width: 23rem;
  }
}
@media (max-width:427px) {
  .footer-logos-con img{
    width: 3rem;
    
  }
}
@media (max-width:399px) {
  .when-background-con,  .grid-elements{
    width: 22rem;
    
  }
  .slider-section-text >h2{
  padding-bottom: 2rem;
  
}
.slider-section-text >h2{
  
  font-size: 1.3rem;
  
}
}
@media (max-width:375px) {
  .slider-section-text >h2{
  color: white;
  margin-top: 0rem;
}
.slider-section-text{
  text-align: center;
  padding-top: 17rem;
 
  
}
.when-background-con{
  width: 20.5rem;
}
}

@media (max-width:360px) {
  .when-background-con,  .grid-elements{
    width: 20.5rem;
    
  }
  .slider-section-text >h2{
  font-size: 1.2rem;
  
}
.grid-element-text{
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  }
  .about-text-con >p{
    text-align: center;
    line-height: 1.5rem;
    font-size: 1.2rem;
  }
  .container{
    margin: 0 0.2rem;
  }
  .when-background-con >h2{
    font-size: 1.8rem;
    padding-top: 1rem;
  }
  
  .feature-section{
    margin-top: 4rem;
  }
}
@media (max-width:367px) {
  .slider-section-text{
    padding-top: 19rem;
   
    
  }
}
@media (max-width:359px) {
  .when-background-con,  .grid-elements{
    width: 19.5rem;
    
  }
  .feature-section{
    margin-top: 4rem;
  }
 
}


@media (max-width:357px) {
  .container{
    margin: 0 0.1rem;
  }
  .feature-section{
  margin-top: 6rem;
}
.when-background-con{
    width: 19rem;
    
  }
  .grid-elements{
    width: 20rem;
  }
}
@media (max-width:345px) {
  .slider-section-text >h2{
    font-size: 1.1rem;
    
  }
  
}
@media (max-width:331px) {
  .when-background-con >h2{
    color: white;
    font-size: 1.5rem;
    text-align: center;
    padding-top: 1rem;
  }
  .when-background-con{
    width: 18rem;
    
  }
  .grid-elements{
    width: 19rem;
  }
  .grid-element-text{
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }
}
@media (max-width:309px) {
  .when-background-con{
    width: 17rem;
  }
}
@media (max-width:307px){
  .when-background-con{
    width: 100%;
  }
  .hero-text-con >h1{
    font-size: 2.5rem;
    
  }
  .hero-img-con > img{
    width: 100%;
  }
}